import { useState } from 'react'
import useM from './useM'

export const useEditElement = () => {
  const [loading, setLoading] = useState(false)
  const createElement = useM('CREATE_ELEMENT')
  const deleteElement = useM('DELETE_ELEMENT')

  return [
    ({ previous, next }) => {
      setLoading(true)
      return createElement({
        variables: {
          input: next,
        },
      })
        .then(res => {
          setLoading(false)
          if (previous?.id) {
            console.log('Deleting previous')
            return deleteElement({ variables: { id: previous.id } })
          }
          console.error('No previous Element Provided for deletion')
        })
        .catch(err => {
          setLoading(false)
        })
    },
    loading,
  ]
}
