import { setLightness, getLuminance } from 'polished'
import {
  // setAlign,
  setMargins,
  setPaddings,
  setBorders,
  setBorderRadii,
  setFlex,
  // setPosition,
} from '~/src/core/theming/utils/cssHelpers'

export const themeDefaults = ({
  colors,
  color,
  palettes: userPalettes,
  palette,
  units: { tem, u, col, cell, prct },
  scales: { scale, h },
  fluid,
  typography,
}) => {
  const fontSizes = {
    h1: tem(h(1)),
    h2: tem(h(3)),
    h3: tem(h(5)),
    h4: tem(scale(0)),
    subTitle: tem(h(5)),
    p: tem(scale(0)),
    centralMenu: tem(scale(4)),
  }
  const paddings = {
    mainX: tem(scale(5)),
    mainY: tem(scale(3)),
  }
  paddings.hero = a => setPaddings({ x: paddings.mainX, y: tem(scale(8)) }, a)
  paddings.section = a => setPaddings({ all: tem(scale(0)) }, a)
  paddings.column = a => setPaddings({ p: tem(scale(0)) }, a)
  paddings.paragraph = a => setPaddings({ p: tem(scale(0)) }, a)
  paddings.h2 = a => setPaddings({ all: tem(h(5)) }, a)
  paddings.h3 = a => setPaddings({ p: tem(h(9)) }, a)
  paddings.button = a => setPaddings({ x: tem(scale(0)), y: tem(scale(-5)) }, a)
  paddings.menu = a => setPaddings({ x: tem(scale(0)), y: tem(scale(0)) }, a)
  paddings.footer = a => setPaddings({ all: tem(scale(0)) }, a)
  paddings.input = a =>
    setPaddings({ x: tem(scale(-7)), y: tem(scale(-10)) }, a)

  const margins = {
    mainX: tem(scale(5)),
    mainY: tem(scale(3)),
  }
  margins.h2 = a => setMargins({ all: tem(h(5)) }, a)
  margins.h3 = a => setMargins({ m: tem(h(9)) }, a)
  margins.section = a => setMargins({ x: margins.mainX, y: margins.mainY }, a)
  margins.column = a => setMargins({ m: tem(scale(0)) }, a)
  margins.picto = a => setMargins({ m: tem(scale(-1)) }, a)
  margins.button = a => setMargins({ m: tem(scale(0)) }, a)
  margins.navLinks = a => setMargins({ m: tem(scale(-3)) }, a)

  margins.centralMenu = a => setMargins({ m: tem(scale(8)) }, a)
  margins.centralMenuButton = a => setMargins({ m: tem(scale(4), 'rem') }, a)

  const borders = {}
  borders.section = a => setBorders({ all: 'solid 1px' }, a)
  borders.toile = a => setBorders({ all: 'solid .2em' }, a)
  borders.formTitle = a => setBorders({ b: 'solid 1px' }, a)
  borders.formListItem = a => setBorders({ b: 'solid 1px' }, a)
  borders.input = a => setBorders({ all: 'dotted .1px' }, a)

  // const borderRadii = {}
  // borderRadii.main = a => setBorderRadii({ all: '1em' }, a)

  // Widths are containers widths while ...
  const widths = {
    unit: u(1), // 0.5em canonical
    cell: col(1 / 3), // === u(10)
    column: col(1), // 15em canonical = 240px
    paragraph: col(2),
    boxed: (s = 1) => ({
      maxWidth: prct(s * 100, 'rem'),
      width: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
    }),
    wide: (s = 1) => ({
      maxWidth: prct(s * 150, 'rem'),
      width: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
    }),
  }
  // Spaces are paddings
  // const spaces = {}

  const styles = {}
  // ------------------------------------------------------------------------
  // GLOBALS
  // ------------------------------------------------------------------------
  styles.globals = {
    html: {
      fontFamily: typography.fontFamily,
      lineHeight: typography.baseLineHeight,
      fontWeight: 400,
      scrollBehavior: 'smooth',
      ...fluid.locks([
        {
          properties: ['fontSize'],
          min: typography.baseFontSize,
          max: typography.fontSizeScale * typography.baseFontSize,
          minW: 30,
          maxW: 60,
          unit: 'rem',
          fullScaleWidth: '100vw',
        },
      ]),
      ...palette(0, 'classic').css.base,
    },
    h1: {
      fontSize: fontSizes.h1,
    },
    h2: {
      fontSize: fontSizes.h2,
    },
    h3: {
      fontSize: fontSizes.h3,
    },

    'button, .button': {
      fontFamily: typography.fontFamily,
      // fontWeight: 300,
      ...paddings.button(),
      // ...margins.button([0, 1, 0, 0]),
    },
    form: {
      ...widths.boxed(),
      ...paddings.section(),
    },
    'button[type=submit]': {
      ...margins.section([1, 0]),
      ...palette(0, 'funkyContrast').css.block,
      ':hover': {
        ...palette(0, 'contrast').css.block,
      },
    },
    ' textarea, input, select': {
      boxSizing: 'border-box',
      ...palette().css.block,
      ...borders.input(),
      ...paddings.input(),
      // maxWidth: 500,
    },
    ' textarea, input': {
      width: '100%',
    },
    'fieldset#root fieldset': {
      ...margins.h2([1, 0, 0, 0]),
      // ...paddings.section([0, 1]),
      legend: {
        fontSize: fontSizes.h3,
        display: 'block',
        ...borders.formTitle(),
      },
      '> legend': {
        // Section titles
        width: '100%',
        fontSize: fontSizes.h2,

        ...margins.h2([0, 0, 0.3, 0]),
      },
    },
    'fieldset#root fieldset fieldset': {
      '> legend': {
        // Section titles
        width: 'auto',
        fontSize: fontSizes.h3,
      },
    },
    '.field-array-of-object': {
      ...borders.section(),
      ...paddings.section(),
    },
    '.array-item': {
      ...setFlex({
        flow: 'row nowrap',
        alignItems: 'center',
        alignContent: 'stretch',
      }),
      ...paddings.column([0, 0, 1, 0]),
      ...borders.formListItem(),
      ...margins.column([0, 0, 1, 0]),
      '> div:first-of-type': {
        flexGrow: 1,
        ...paddings.column([0, 1, 0, 0]),
      },
    },
    '.array-item-toolbox > .btn-group': {
      ...setFlex({ flow: 'column', justify: 'stretch', alignItems: 'stretch' }),
    },

    'i.glyphicon': {
      display: 'none',
    },
    '.btn-add::after': { content: `'Add'` },
    '.array-item-move-up::after': { content: `'⇑'`, fontSize: fontSizes.h3 },
    '.array-item-move-down::after': { content: `'⇓'`, fontSize: fontSizes.h3 },
    '.array-item-remove::after': { content: `'X'`, fontSize: fontSizes.h3 },
  }

  return {
    // colors,
    fontSizes,
    paddings,
    margins,
    borders,
    widths,
    styles,
  }
}
