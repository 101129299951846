import React, { useRef, useState } from 'react'
import { toast } from 'react-toastify'

import { useTheming } from '~/src/core'
import Image from '~/src/core/components/Image'
import imgur from '../../services/imgur'
import { useQ, useM, useEditElement } from '../../services/toile'

import { imgProps } from '../../services/imgur/utils'
import FilePond from '../../components/FilePond'
import Loader from '../../components/Loader'

const websiteId = process.env.GATSBY_WEBSITE_ID

// const mlBaseProperties = {
//   name: 'MediaLibrary',
//   locale: 'fr',
//   contents: { imgurAlbumId: globalImgurAlbumId, images: [] },
// }

const PageAdminMedia = () => {
  const [editElement, editElementIsLoading] = useEditElement()
  // const createElement = useM('CREATE_ELEMENT')
  // const updateElement = useM('UPDATE_ELEMENT')
  // const deleteElement = useM('DELETE_ELEMENT')
  const { data, error, loading, refetch: refreshWebsiteElements } = useQ(
    'WEBSITE_ELEMENTS',
  )
  const {
    cssHelpers: { setAlign, setFlex },
    fluid,
    defaults: { widths, paddings },
  } = useTheming()

  const refFilePond = useRef(null)
  const [localLoading, setLocalLoading] = useState(false)

  if (loading) return <Loader />
  if (error) return `Error! ${error.message}`

  // Isolate important values from DB
  const [website] = (data?.currentUser?.websites || []).filter(
    ({ id }) => id === websiteId,
  )
  if (!website) {
    return 'You need to sign in again'
  }

  const elements = website?.elements || []

  const [mediaLibrary, mediaLibraryComponent] = elements.reduce(
    (accu, element) => {
      const compo = (element && element.component) || {}
      const { name: componentName } = compo

      return componentName === 'MediaLibrary' ? [element, compo] : accu
    },
    [],
  )
  // --------------------------------------------------------------------------

  const { id: componentId } = mediaLibraryComponent || {}
  const { id: elementId, name, locale, isPublished, contents } =
    mediaLibrary || {}

  const { imgurAlbumId, images: imagesLibrary = [] } = contents || {}

  // --------------------------------------------------------------------------

  const addMedia = ({ previousML, newMedia = [] }) => {
    const images = [
      ...previousML?.contents?.images, // Just comment this line to reinitialize images
      ...newMedia,
    ].filter(i => i) // to avoid null values in array in case form is sent empty

    const cts = {
      ...previousML?.contents,
      images,
    }

    return editElement({
      previous: previousML,
      next: {
        name: 'MediaLibrary',
        locale: 'fr',
        website: websiteId,
        component: componentId,
        contents: cts,
      },
    }).then(() => {
      refreshWebsiteElements()
      // toast('Data successfuly saved')
    })
  }

  return (
    <div
      css={{
        ...widths.boxed(),
        ...setAlign('right'),
      }}
    >
      {(editElementIsLoading || localLoading) && (
        <Loader css={{ position: 'fixed' }} />
      )}
      <div
        css={{
          ...paddings.section(),
        }}
      >
        <FilePond ref={refFilePond} />
        <button
          type="submit"
          css={{
            margin: '0 !important',
          }}
          onClick={() => {
            setLocalLoading(true)
            const files = refFilePond.current.getFiles().map(fPond => ({
              image: fPond.source,
              name: fPond.filename,
              title: fPond.filenameWithoutExtension,
            }))

            imgur
              .uploads({
                album: imgurAlbumId,
                images: files,
                options: { format: true },
              })
              .then(filesData => {
                console.log(filesData)
                addMedia({ previousML: mediaLibrary, newMedia: filesData })
              })
              .then(() => {
                refFilePond.current.removeFiles()
                refreshWebsiteElements()
                toast(`Image(s) received.`)
                setLocalLoading(false)
              })
              .catch(err => {
                refFilePond.current.removeFiles()
                refreshWebsiteElements()
                toast(`Error saving images: ${err}`)
                setLocalLoading(false)
              })
          }}
        >
          Send
        </button>
      </div>
      <div
        css={{
          ...setFlex(),
          ...paddings.section(),
          // '.image': {
          //   ...fluid.column({ properties: ['width'], maxCols: 6 }),
          // },
        }}
      >
        {imagesLibrary &&
          imagesLibrary.map(image => {
            const img = imgProps(image)
            return (
              <Image
                {...{
                  key: img.id,
                  data: {
                    ...img,
                  },
                  // isFluid: true,
                  shape: 'square',
                  css: {
                    ...fluid.column({ properties: ['width'], maxCols: 6 }),
                  },
                }}
              />
            )
          })}
      </div>
    </div>
  )
}

export default PageAdminMedia
