import {
  renderSchema,
  Components as CoreLibrary,
  // useContext,
  // ThemeContext,
  ThemeProvider,
  // RenderSchemaContext,
  RenderSchemaProvider,
  useTheming,
  useRenderSchema,
} from '~/src/core'
import { themeDefaults as defaults } from '../../themeDefaults'

const GlobalStyles = ({ children }) => {
  const rs = useRenderSchema()
  const theming = useTheming()
  const {
    defaults: {
      styles: { globals: globalStyles },
    },
  } = theming

  return rs({
    schema: {
      component: 'GlobalStyles',
      key: 'GlobalStyles',
      styles: globalStyles,
      children,
    },
  })
}

const Theming = ({ children }) => {
  const theme = {
    colors: {
      blue: '#002664',
      white: `#fff`,
      grey: `#5E6A71`,
      orange: '#FF7900',
    },
    palettes: [
      {
        name: 'default',
        neutral: 'white',
        primary: 'blue',
        secondary: 'orange',
      },
      {
        name: 'alt',
        neutral: 'white',
        primary: 'grey',
        secondary: 'blue',
      },
      {
        name: 'funky',
        neutral: 'white',
        primary: 'grey',
        secondary: 'orange',
      },
    ],

    fontFamily: 'Cuprum,Open Sans',
    baseFontSize: 1,
    h1FontSize: 2.25,
    baseLineHeight: 1.4,
    // fontSizeScale = 1.225,
    fullWidth: 60, // this is in em
    // fullScaleWidth = 0.8,
    // ...userTheme,
  }

  const schema = {
    component: RenderSchemaProvider,
    library: { ...CoreLibrary },
    children: {
      component: ThemeProvider,
      key: 'ThemeProvider',
      theme,
      defaults,
      children: [
        {
          component: CoreLibrary.CssReset,
          key: 'CoreLibrary.CssReset',
          children: [
            {
              component: GlobalStyles,
              children,
            },
          ],
        },
      ],
    },
  }

  return renderSchema({
    schema,
  })
}

export default Theming
