// PACKAGE IMPORTS
// ==========================================================================
import React from 'react'
import { FilePond as FP, registerPlugin } from 'react-filepond'
// import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import 'filepond/dist/filepond.min.css'
// import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'

// registerPlugin(FilePondPluginImagePreview)
// UTILS & FUNCTIONS IMPORTS
// ==========================================================================
// CONTAINERS IMPORTS
// ==========================================================================
// COMPONENTS IMPORTS
// ==========================================================================
// DATA/CSS/IMAGES IMPORTS
// ==========================================================================
// ###########################################################################
// END OF IMPORTS
// ###########################################################################
// FUNCTIONS
// ==========================================================================

// CONSTANTS
// ==========================================================================
// COMPONENTS
// ==========================================================================

// ###########################################################################
// MAIN COMPONENT
// ###########################################################################
const FilePond = React.forwardRef((props, ref) => {
  // LOCAL CONSTANTS
  // ==========================================================================
  // LOCAL STATE
  // ==========================================================================
  // LOCAL FUNCTIONS
  // ==========================================================================
  // LOCAL VARIABLES
  // ==========================================================================
  // LOCAL STYLES
  // ==========================================================================
  // RETURN
  // ***************************************************************************
  return (
    <FP
      {...{
        ...props,
        ref,
        allowMultiple: true,
        allowReplace: false,
        // dropOnPage: true,
        instantUpload: false,
        css: {
          '.filepond--item': {
            maxWidth: `calc(20% - .5em)`,
          },
        },
      }}
    />
  )
})

export default FilePond
