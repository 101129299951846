import gql from 'graphql-tag'

export const CREATE_ELEMENT = gql`
  mutation createElement($input: ElementInput) {
    createElement(input: $input) {
      id
      website {
        id
        name
      }
      component {
        id
        name
      }
    }
  }
`

export const UPDATE_ELEMENT = gql`
  mutation updateElement($id: ID!, $input: ElementInput) {
    updateElement(id: $id, input: $input) {
      id
      website {
        id
        name
      }
      component {
        id
        name
      }
    }
  }
`

export const DELETE_ELEMENT = gql`
  mutation deleteElement($id: ID!) {
    deleteElement(id: $id) {
      id
    }
  }
`
