const formatImageData = ({
  id,
  name: imageName,
  title,
  description: imageDescription,
  type,
  link,
  width,
  height,
}) => ({
  source: 'imgur',
  sourceId: id,
  url: link,
  id,
  name: imageName,
  description: imageDescription,
  title,
  type,
  width,
  height,
})

// ID like QUJcHhA gives
// URL like https://i.imgur.com/QUJcHhA.jpg for original image AND
// URL like https://i.imgur.com/QUJcHhAs.jpg for small square image
const idExtensions = {
  original: '',
  squareSmall: 's',
  squareBig: 'b',
  thumbnailSmall: 't',
  thumbnailMedium: 'm',
  thumbnailLarge: 'l',
  thumbnailHuge: 'h',
}

const url = ({ id, src, size }) =>
  // `https://i.imgur.com/${id}${imgurIdExtensions[size || 'original']}.jpg`
  src.replace(id, `${id}${idExtensions[size || 'original']}`)

const imgProps = img => {
  const aspectRatio = img.width / img.height
  const src = img.url
  const sizeUrl = size => url({ id: img.sourceId, src, size })
  const thumbnails = Object.entries(idExtensions).reduce(
    (accu, [key, ext]) => ({
      ...accu,
      [key]: sizeUrl(key),
    }),
    {},
  )

  const responsiveProps = [
    { w: 160, thumbnailId: 'thumbnailSmall' },
    { w: 320, thumbnailId: 'thumbnailMedium' },
    { w: 640, thumbnailId: 'thumbnailLarge' },
    { w: 1024, thumbnailId: 'thumbnailHuge' },
  ].map(({ w, thumbnailId }) => {
    return {
      w: aspectRatio >= 1 ? w : Math.round(w / aspectRatio),
      tUrl: thumbnails[thumbnailId],
    }
  })
  const srcSetMinimal = `${src} ${img.width}w`
  const srcSet = responsiveProps
    .map(({ w, tUrl }) => `${tUrl} ${w}w`)
    .join(', ')
  const sizesMinimal = `${img.width}px`
  const sizes = [
    ...responsiveProps.map(({ w }) => `(max-width: ${w}px) ${w}px`),
    sizesMinimal,
  ].join(', ')
  // const sizes = [
  //   `(max-width: 320px) 280px`,
  //   `(max-width: 480px) 440px`,
  //   `800px`,
  // ].join(', ')

  const alt = img.title || img.name

  const props = /png/.test(img.type)
    ? { src, srcSet: srcSetMinimal, sizes: sizesMinimal, aspectRatio, alt }
    : { src, srcSet, sizes, aspectRatio, alt }

  return {
    ...img,
    aspectRatio,
    thumbnails,
    src,
    srcSet,
    sizes,
    props,
  }
}

module.exports = { formatImageData, idExtensions, url, imgProps }
