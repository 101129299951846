import React from 'react'
// import { graphql } from 'gatsby'
import { Router } from '@reach/router'
// import 'glyphicons'
// import 'bootstrap-css-only'

import ApolloProvider from '../containers/ApolloProvider'
import { useQ } from '../services/toile'

import Theming from '../routes/admin/layout/Theming'
import TemplateAdmin from '../routes/admin/layout'
import PageAdmin from '../routes/admin'
import PageAdminOnePage from '../routes/admin/one-page'
import PageAdminSettings from '../routes/admin/settings'
import PageAdminMedia from '../routes/admin/media'

import Signin from '../components/Signin'
import Loader from '../components/Loader'

const AdminRoutes = props => {
  const { data, error, loading } = useQ('CURRENT_USER')
  if (loading) return <Loader css={{ background: 'white' }} />
  if ((error && !error.networkError) || !data) {
    return <Signin />
  }
  if (error) return `Error! ${error.message}`

  return (
    <Router>
      {/* <Nothing default /> */}

      <TemplateAdmin {...{ path: '/admin/' }}>
        <PageAdmin
          {...{
            path: '/',
            // staticData: {
            //   posts,
            //   templates,
            // },
          }}
        />
        <PageAdminOnePage {...{ path: '/one-page/' }} />
        <PageAdminSettings {...{ path: '/settings/' }} />
        <PageAdminMedia {...{ path: '/media/' }} />
      </TemplateAdmin>
    </Router>
  )
}

export default props =>
  typeof window === 'undefined' ? null : (
    <ApolloProvider>
      <Theming>
        <AdminRoutes {...props} />
      </Theming>
    </ApolloProvider>
  )
