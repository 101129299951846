import React from 'react'
import ApolloClient from 'apollo-boost'
import { ApolloProvider as Provider } from 'react-apollo-hooks'

import { useLocalStorage } from '../hooks'

const ApolloProvider = ({ children }) => {
  const [userToken, setUserToken] = useLocalStorage('user-token', null, {
    isSessionStorage: true,
  })
  const apolloOptions = {
    uri: 'https://backend.toile.io/',
    ...(userToken && {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    }),
  }
  const createApolloClient = new ApolloClient(apolloOptions)

  // TODO: use a global state and reducer because state is not shared between instances of custom hooks
  // const client = useRef(createApolloClient)
  // useEffect(() => {
  //   console.log('EFFECT TRIGERED')
  //   client.current = createApolloClient
  // }, [userToken])

  const client = createApolloClient

  return <Provider client={client}>{children}</Provider>
}

export default ApolloProvider
