import React, { useState } from 'react'
import { css } from '@emotion/core'

// import useInterval from '../hooks/useInterval'

const colors = ['#ff7900', '#ffffff']

const Loader = ({ delay = 12.8, className }) => {
  // const [count, setCount] = useState(0)
  //
  // console.log(delay * 1000)
  //
  // useInterval(() => {
  //   setCount((count + 1) % 2)
  // }, delay * 1000)

  return (
    <div
      {...{
        className,
        css: {
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          zIndex: 20,
          backgroundColor: `rgba(255, 255, 255, 0.5)`,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        },
      }}
    >
      <div
        className="spinner-eff spinner-eff-4"
        css={css`
          margin: auto;
          /* spinner style */
          position: relative;
          width: 50px;
          height: 50px;
          :before,
          :after {
            content: '';
            display: block;
          }
          .spinner-bar:before,
          .spinner-bar:after {
            content: '';
            display: block;
          }
          /* spinner-4 styles */
          ${'' /* animation: rotation ${delay}s steps(1) 0s infinite;
        @keyframes rotation {
          0% {
            transform: rotate(0deg);
          }
          25% {
            transform: rotate(90deg);
          }
          50% {
            transform: rotate(180deg);
          }
          75% {
            transform: rotate(270deg);
          }
          100% {
            transform: rotate(360deg);
          }
        } */}
          @keyframes bar-top {
            0% {
              transform: scale(0, 1);
            }
            12.5% {
              transform: scale(1, 1);
            }
            87.5% {
              transform: scale(1, 1);
            }
            100% {
              transform: scale(0, 1);
            }
          }
          @keyframes bar-right {
            0% {
              transform: scale(1, 0);
            }
            12.5% {
              transform: scale(1, 0);
            }
            25% {
              transform: scale(1, 1);
            }
            75% {
              transform: scale(1, 1);
            }
            87.5% {
              transform: scale(1, 0);
            }
            100% {
              transform: scale(1, 0);
            }
          }
          @keyframes bar-bottom {
            0% {
              transform: scale(0, 1);
            }
            25% {
              transform: scale(0, 1);
            }
            37.5% {
              transform: scale(1, 1);
            }
            62.5% {
              transform: scale(1, 1);
            }
            75% {
              transform: scale(0, 1);
            }
            100% {
              transform: scale(0, 1);
            }
          }
          @keyframes bar-left {
            0% {
              transform: scale(1, 0);
            }
            37.5% {
              transform: scale(1, 0);
            }
            50% {
              transform: scale(1, 1);
            }
            62.5% {
              transform: scale(1, 0);
            }
            100% {
              transform: scale(1, 0);
            }
          }
          .bar {
            background-color: ${colors[0]};
          }
          .bar-top {
            width: 100%;
            height: 7px;
            position: absolute;
            top: 0;
            left: 0;
            transform-origin: left top;
            transform: scale(0, 1);
            animation: bar-top ${delay / 4}s linear 0s infinite;
          }
          .bar-right {
            width: 7px;
            height: 100%;
            position: absolute;
            top: 0;
            right: 0;
            transform-origin: left top;
            transform: scale(1, 0);
            animation: bar-right ${delay / 4}s linear 0s infinite;
          }
          .bar-bottom {
            width: 100%;
            height: 7px;
            position: absolute;
            right: 0;
            bottom: 0;
            transform-origin: right top;
            transform: scale(0, 1);
            animation: bar-bottom ${delay / 4}s linear 0s infinite;
          }
          .bar-left {
            width: 7px;
            height: 100%;
            position: absolute;
            left: 0;
            bottom: 0;
            transform-origin: left bottom;
            transform: scale(1, 0);
            animation: bar-left ${delay / 4}s linear 0s infinite;
          }
        `}
      >
        <div className="bar bar-top" />
        <div className="bar bar-right" />
        <div className="bar bar-bottom" />
        <div className="bar bar-left" />
      </div>
    </div>
  )
}

export default Loader
