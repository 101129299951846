import React from 'react'
// import { Formik } from 'formik'
// import { flatten } from 'flat'
// import * as deepmerge from 'deepmerge'
import Form from 'react-jsonschema-form'
// import extraFields from 'react-jsonschema-form-extras'
// import { TypeaheadField } from "react-jsonschema-form-extras/lib/TypeaheadField"
import { toast } from 'react-toastify'

// UTILS & FUNCTIONS IMPORTS
// ==========================================================================
import renderSchema from '../../core/functions/renderSchema'
import { useQ, useEditElement } from '../../services/toile'
// import * as FormSettingsComponents from '../../components/forms/Settings'

import Loader from '../../components/Loader'

const { default: extraFields } =
  typeof window !== 'undefined' && require('react-jsonschema-form-extras')

// ###########################################################################
// END OF IMPORTS
// ###########################################################################

// CONSTANTS
// ==========================================================================

const log = type => console.log.bind(console, type)

const uiSchema = {
  metadata: {
    description: {
      // 'ui:field': 'rte',
      // rte: {
      //   format: 'html',
      // },
      'ui:widget': 'textarea',
      'ui:options': {
        rows: 5,
      },
    },
  },
  theme: {
    colors: {
      items: {
        value: {
          'ui:widget': 'color',
        },
      },
    },
    palettes: {
      'ui:options': {
        // addable: false,
        // orderable: false,
        // removable: false,
      },
    },
  },
}
const dataStructure = {
  type: 'object',
  properties: {
    metadata: {
      type: 'object',
      // title: 'metadata',
      properties: {
        baseUrl: { type: 'string', title: 'Base URL' },
        name: { type: 'string', title: 'name' },
        title: { type: 'string', title: 'title' },
        description: { type: 'string', title: 'description' },
        socialImage: { type: 'string', enum: [], enumNames: [] },
        favicon: { type: 'string', enum: [], enumNames: [] },
      },
    },
    theme: {
      type: 'object',
      properties: {
        name: { type: 'string', enum: ['forty', 'stellar'] },
        colors: {
          type: 'array',
          items: {
            type: 'object',
            properties: {
              name: { type: 'string' },
              value: { type: 'string' },
            },
          },
        },
        palettes: {
          type: 'array',
          items: {
            type: 'object',
            properties: {
              name: { type: 'string' },
              neutral: { type: 'string' },
              primary: { type: 'string' },
              secondary: { type: 'string' },
            },
          },
        },
      },
    },
    contactInfos: {
      type: 'object',
      properties: {
        phone: { type: 'string' },
        email: { type: 'string' },
        address: { type: 'string' },
        // facebook: { type: 'string', format: 'uri' },
        facebook: { type: 'string' },
        twitter: { type: 'string' },
        instagram: { type: 'string' },
        github: { type: 'string' },
        linkedin: { type: 'string' },
        youtube: { type: 'string' },
      },
    },
  },
}

const websiteId = process.env.GATSBY_WEBSITE_ID

// ###########################################################################
// MAIN COMPONENT
// ###########################################################################
const PageFormSettings = ({ ...props }) => {
  const [editElement, editElementIsLoading] = useEditElement()
  const { data, error, loading, refetch: refreshWebsiteElements } = useQ(
    'WEBSITE_ELEMENTS',
  )

  // ----------------------------------------------------------------------------
  if (loading) return <Loader />
  if (error) return `Error! ${error.message}`
  // --------------------------------------------------------------------------

  // Isolate important values from DB
  const website = data?.currentUser?.websites[0] || {}
  const elements = website?.elements || []
  const {
    SettingsElement,
    // OnePageComponent,
    MediaLibraryElement,
    // MediaLibraryComponent,
    ...rest
  } = elements.reduce((accu, element) => {
    const { name: elementName, component: compo } = element || {}
    const { name: componentName } = compo
    return {
      ...accu,
      [`${elementName}Element`]: element,
      [`${componentName}Component`]: compo,
    }
  }, {})
  // --------------------------------------------------------------------------

  // Create Select fields from image library
  const mediaLibraryImages = MediaLibraryElement?.contents?.images || []
  const imagesEnum = mediaLibraryImages.map(({ id }) => id)
  const imagesEnumNames = mediaLibraryImages.map(({ name }) => name)
  dataStructure.properties.metadata.properties.socialImage.enum = imagesEnum
  dataStructure.properties.metadata.properties.socialImage.enumNames = imagesEnumNames
  dataStructure.properties.metadata.properties.favicon.enum = imagesEnum
  dataStructure.properties.metadata.properties.favicon.enumNames = imagesEnumNames
  // --------------------------------------------------------------------------

  // Current content to prefill the form
  const currentContents = SettingsElement?.contents
  // --------------------------------------------------------------------------
  const onSubmit = ({ formData }) => {
    console.log('formData', formData)

    return editElement({
      previous: SettingsElement,
      next: {
        name: 'Settings',
        locale: 'fr',
        website: websiteId,
        component: SettingsElement.component.id,
        contents: formData,
      },
    })
      .then(() => {
        refreshWebsiteElements()
        toast('Data successfuly saved')
      })
      .catch(err => {
        toast(`Error data submition: ${err}`)
      })
  }

  return renderSchema({
    schema: [
      editElementIsLoading && {
        component: Loader,
        css: {
          position: 'fixed',
        },
      },
      {
        component: Form,
        fields: extraFields,
        // onChange: log('changed'),
        onSubmit,
        onError: log('errors'),
        css: {
          // ' label > span': {
          //   display: 'block',
          //   paddingTop: '1em',
          //   fontWeight: 'bold',
          // },
          ' textarea, input': {
            width: '100%',
            maxWidth: 500,
          },
          button: {
            padding: '.5em',
          },
        },
        formData: currentContents,
        uiSchema,
        schema: dataStructure,
      },
    ],
  })
}

export default PageFormSettings
