import axios from 'axios'
// require('dotenv').config()
// import imgur from 'imgur'
import { formatImageData } from './utils'

const ACCESS_TOKEN = process.env.GATSBY_IMGUR_ACCESS_TOKEN
// const USERNAME = process.env.imgurUsername
const MASHAPE_KEY = process.env.GATSBY_MASHAPE_KEY

// for non-commercial use
// const BASE_URL = 'https://api.imgur.com/3/'
// for commercial use
const BASE_URL = 'https://imgur-apiv3.p.rapidapi.com/3/'

// const toileTestAlbum = 'is2tqBT'

const axiosOptions = {
  baseURL: BASE_URL,
  mode: 'cors',
  headers: {
    'Content-Type': 'application/form-data',
    // 'Content-Type': 'application/x-www-form-urlencoded', // Maybe this for RapidAPI
    // Authorization: `Client-ID ${CLIENT_ID}`,
    Authorization: `Bearer ${ACCESS_TOKEN}`,
    'X-RapidAPI-Key': MASHAPE_KEY,
  },
  // params: {
  //   id: gameIds,
  //   stats: '1',
  //   type: 'boardgame,primary',
  // },
}

class Imgur {
  // image = ({ id }) => {
  //   return imgur
  //     .getInfo(id)
  //     .then(json => {
  //       console.log(json)
  //     })
  //     .catch(err => {
  //       console.error(err.message)
  //     })
  // }

  // searchGallery = (section = 'hot', sort = 'viral', page = 0) => {
  //   return axios
  //     .get(`gallery/${section}/${sort}/${page}`, axiosOptions)
  //     .then(response => {
  //       // eslint-disable-next-line no-console
  //       console.log(response)
  //     })
  //     .catch(error => {
  //       console.error(error)
  //     })
  // }

  // refreshToken = () => {
  //   return axios
  //     .post(`https://api.imgur.com/oauth2/token`, {
  //     	refresh_token: {{refreshToken}},
  //     	client_id: {{clientId}},
  //     	client_secret: {{clientSecret}},
  //     	grant_type: "refresh_token"
  //     }, {
  //       headers: {
  //         'Content-Type': 'application/x-www-form-urlencoded'
  //       }
  //     })
  //     .then(res => {
  //       // eslint-disable-next-line no-console
  //       console.log(res)
  //     })
  //     .catch(err => {
  //       console.error(err)
  //     })
  // }

  // count = () => {
  //   return axios
  //     .get(`account/${USERNAME}/images/count`, axiosOptions)
  //     .then(res => {
  //       // eslint-disable-next-line no-console
  //       console.log(res)
  //     })
  //     .catch(err => {
  //       console.error(err)
  //     })
  // }

  // image = ({ id }) => {
  //   return axios
  //     .get(`image/${id}`, axiosOptions)
  //     .then(res => {
  //       // eslint-disable-next-line no-console
  //       console.log(res)
  //     })
  //     .catch(err => {
  //       console.error(err)
  //     })
  // }

  upload = ({ album, image, name, title, description, options }) => {
    if (!album) {
      throw new Error('We need an album to post to')
    }
    const { format } = options || {}

    const formData = new FormData()
    formData.append('album', album)
    formData.append('image', image)
    formData.append('type', 'file')
    formData.append('name', name || '')
    formData.append('title', title || '')
    formData.append('description', description || '')

    return axios
      .post(`image`, formData, axiosOptions)
      .then(res => {
        if (format) {
          return formatImageData(res.data.data)
        }
        return res
      })
      .catch(err => {
        console.error(err)
        return null
      })
  }

  uploads = ({ album, images, options }) => {
    return Promise.all(
      images.map(image => this.upload({ ...image, album, options })),
    ).then(values => {
      console.log(values)
      return values
    })
  }
}

export default new Imgur()
