import gql from 'graphql-tag'

export const CURRENT_USER = gql`
  {
    currentUser {
      id
    }
  }
`

export const WEBSITE_ELEMENTS = gql`
  {
    currentUser {
      id
      websites {
        id
        customProperties
        elements {
          id
          component {
            id
            name
            contents
          }
          name
          locale
          contents
          isPublished
          contents
        }
      }
    }
  }
`
