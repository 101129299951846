// PACKAGE IMPORTS
// ==========================================================================
import React, { useState } from 'react'
import { useMutation } from 'react-apollo-hooks'
import gql from 'graphql-tag'
import { Formik } from 'formik'
import { Form, Input } from 'react-formik-ui'

import { useLocalStorage } from '../hooks'
import Loader from './Loader'

// UTILS & FUNCTIONS IMPORTS
// ==========================================================================

// CONTAINERS IMPORTS
// ==========================================================================
// COMPONENTS IMPORTS
// ==========================================================================
// DATA/CSS/IMAGES IMPORTS
// ==========================================================================
// ###########################################################################
// END OF IMPORTS
// ###########################################################################
// FUNCTIONS
// ==========================================================================
// CONSTANTS
// ==========================================================================
const initialValues = { email: '', password: '' }

const CURRENT_USER = gql`
  {
    currentUser {
      id
      isAdmin
    }
  }
`
const SIGNIN = gql`
  mutation signin($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      token
      user {
        id
      }
    }
  }
`
// COMPONENTS
// ==========================================================================

// ###########################################################################
// MAIN COMPONENT
// ###########################################################################
const Signin = ({ className }) => {
  const [userToken, setUserToken] = useLocalStorage('user-token', '', {
    isSessionStorage: true,
  })
  // const {
  //   data: { currentUser },
  //   // error,
  //   loading: currentUserIsLoading,
  // } = useQuery(CURRENT_USER)
  const signin = useMutation(SIGNIN)
  const [loading, setLoading] = useState(false)

  // LOCAL CONSTANTS
  // ==========================================================================

  // LOCAL STATE
  // ==========================================================================
  // LOCAL FUNCTIONS
  // ==========================================================================
  const handleSignin = ({ email, password }) =>
    signin({ variables: { email, password } })
  // LOCAL VARIABLES
  // ==========================================================================
  // LOCAL STYLES
  // ==========================================================================
  const s = {
    wrapper: {
      // display: 'flex',
    },
    Form: {
      // position: 'relative',
      margin: '20vh auto 5vh',
      maxWidth: 300,
      ' .form-element': {
        paddingBottom: '0.5rem',
      },
      ' label': {
        display: 'flex',
        justifyContent: 'space-between',
      },
      ' button': {
        float: 'right',
      },
    },
  }
  // RETURN
  // ***************************************************************************
  return (
    <Formik
      {...{
        className,
        initialValues,
        onSubmit: ({ email, password }, { resetForm }) => {
          setLoading(true)
          handleSignin({ email, password })
            .then(({ data }) => {
              const { token } = data.login
              // sessionStorage.setItem('user-token', token)
              setUserToken(token)
              resetForm(initialValues)
              setLoading(false)
              // eslint-disable-next-line no-alert
              alert(`Yeah! Let's make a beautiful website!`)
              window.location.reload()
            })
            .catch(e => {
              setLoading(false)
              // eslint-disable-next-line no-console
              console.error(e.message)
              // eslint-disable-next-line no-alert
              alert(e.message)
            })
          // resetForm(initialValues)
          return null
        },
      }}
    >
      {() => (
        <div css={s.wrapper}>
          {loading && <Loader css={{ background: 'white' }} />}
          <Form css={s.Form}>
            <Input
              {...{
                name: 'email',
                type: 'email',
                // label: 'email',
                placeholder: 'email',
                // disabled: disabled || !selectedTemplateType,
              }}
            />
            <Input
              {...{
                name: 'password',
                type: 'password',
                // label: 'password',
                placeholder: 'password',
                // disabled: disabled || !selectedTemplateType,
              }}
            />
            <button
              {...{
                type: 'submit',
                children: 'Connexion',
              }}
            />
          </Form>
        </div>
      )}
    </Formik>
  )
}

export default Signin
