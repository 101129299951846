import React from 'react'

import {
  useTheming,
  // useRenderSchema,
} from '~/src/core'
import { Link } from '~/src/core/components'
import routesMap from '../map'

const PageAdmin = props => {
  const {
    // units: { cell },
    cssHelpers: { setFlex, setAlign },
    fluid,
    defaults: { margins, borders, fontSizes },
    palette,
  } = useTheming()
  // const { renderSchema } = useContext(RenderSchemaContext)

  return (
    <main>
      <section
        {...{
          css: {
            ...setFlex({ justify: 'center' }),
            ...setAlign('center'),
            width: 'auto',
            ...margins.centralMenu([1, 0]),
            marginRight: 'auto',
            marginLeft: 'auto',
            fontSize: fontSizes.centralMenu,
          },
        }}
      >
        {[
          { route: 'adminFormMedia', text: 'Media' },
          { route: 'adminFormSettings', text: 'Settings' },
          { route: 'adminFormOnePage', text: 'Content' },
        ].map(({ route, text }) => (
          <div
            {...{
              key: route,
              css: {
                ...fluid.column({ properties: ['width'], maxCols: 4 }),
              },
            }}
          >
            <Link
              {...{
                to: routesMap[route],
                tag: 'button',
                css: {
                  ...borders.toile(),
                  ...margins.centralMenuButton([0, 0, 1, 0]),
                  ':hover': {
                    ...palette(0, 'funky').css.block,
                  },
                },
              }}
            >
              {text}
            </Link>
          </div>
        ))}
      </section>
    </main>
  )
}

export default PageAdmin
