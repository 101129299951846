import React, { useState } from 'react'
import axios from 'axios'
// import { useApolloClient } from 'react-apollo-hooks'
import { ToastContainer, Slide, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import {
  renderSchema,
  // Components as CoreLibrary,
  // useContext,
  // ThemeContext,
  // ThemeProvider,
  // RenderSchemaContext,
  // RenderSchemaProvider,
  useTheming,
  // useRenderSchema,
} from '~/src/core'
// import { useLocalStorage, useInterval } from '~/src/hooks'
import { Link } from '~/src/core/components'

// const NetlifyStatusImage = ({ src: source, delay = 2000 }) => {
//   const [count, setCount] = useState(0)
//   const [src, setSrc] = useState(source)
//
//   useInterval(() => {
//     // Your custom logic here
//     setCount(count + 1)
//     setSrc(`${source}/?${count}`)
//   }, delay)
//
//   return source ? <img {...{ src, alt: 'Netlify Status' }} /> : null
// }

const TopMenu = () => {
  const {
    units: { cell },
    cssHelpers: { setAlign },
    fluid,
    defaults: { paddings, widths, margins },
    palette,
  } = useTheming()
  // const client = useApolloClient()
  // const [userToken, setUserToken] = useLocalStorage('user-token', '', {
  //   isSessionStorage: true,
  // })

  return (
    <div
      {...{
        css: { ...setAlign('right'), ...widths.boxed(), ...paddings.menu() },
      }}
    >
      {/* <NetlifyStatusImage
        {...{ src: process.env.GATSBY_NETLIFY_STATUS_IMAGE }}
      /> */}

      <button
        type="button"
        css={{
          ...palette('funky').css.button,
          // ...palette(0, 'classic').css.border,
          ':hover': {
            ...palette('funky', 'funky').css.button,
            // ...palette(1, 'classic').css.block,
          },
          // position: 'absolute',
          // top: '1rem',
          // right: '30px',
        }}
        onClick={() => {
          if (
            confirm(`Are you sure you want to publish your Production website?`)
          ) {
            axios
              .post(process.env.GATSBY_ADMIN_PROD_WEBHOOK, '{}')
              .then(response => {
                console.log(response)
                toast('Deployment process started')
              })
              .catch(error => {
                console.log(error)
                toast(`Oops. There is a problem: ${error}`)
              })
          }
        }}
      >
        Publish on Prod
      </button>

      <button
        type="button"
        css={{
          ...margins.button([0, 0, 0, 1]),
          ...palette(0, 'contrast').css.block,
          ...palette(0, 'classic').css.border,
          ':hover': {
            ...palette(0, 'classic').css.block,
          },
          // position: 'absolute',
          // top: '1rem',
          // right: '30px',
        }}
        onClick={() => {
          axios
            .post(process.env.GATSBY_ADMIN_STAGE_WEBHOOK, '{}')
            .then(response => {
              console.log(response)
              toast('Deployment process started')
            })
            .catch(error => {
              console.log(error)
              toast(`Oops. There is a problem: ${error}`)
            })
        }}
      >
        Publish on Stage
      </button>
      {/* <button
        type="button"
        css={
          {
            // position: 'absolute',
            // top: '1rem',
            // right: '30px',
          }
        }
        onClick={() => {
          setUserToken('')
          client.resetStore()
        }}
      >
        Déconnexion
      </button> */}
    </div>
  )
}

const Footer = () => {
  const {
    fluid,
    cssHelpers: { setFlex, setAlign },
    defaults: { paddings },
    palette,
  } = useTheming()

  return (
    <div
      {...{
        css: {
          ...setFlex({ justify: 'center' }),
          ...setAlign('center'),
          ...palette(0, 'contrast').css.block,
          ...paddings.footer([1, 0]),
          div: {
            ...fluid.column({ properties: ['width'], maxCols: 4 }),
          },
        },
      }}
    >
      <div>
        Powered by
        <br />
        <Link to="https://www.toile.io/">toile.io</Link>
      </div>
      <div>
        Imagined and designed by
        <br />
        <Link to="https://www.feedbydesign.com/">Feed by Design</Link>
      </div>
      <div>
        Chat and support on
        <br />
        <Link to="https://spectrum.chat/toile-io">Spectrum.chat</Link>
      </div>
    </div>
  )
}

const LayoutAdmin = ({ children }) => {
  const schema = [
    {
      component: 'div',
      css: {
        minHeight: '100vh',
        display: 'flex',
        flexFlow: 'column',
      },
      children: [
        {
          component: TopMenu,
        },
        {
          component: 'div',
          css: {
            flexGrow: 1,
          },
          children,
        },
        {
          component: Footer,
        },
        {
          component: ToastContainer,
          position: 'top-center',
          autoClose: 3000,
          hideProgressBar: true,
          transition: Slide,
        },
      ],
    },
  ]

  return renderSchema({
    schema,
  })
}

export default LayoutAdmin
