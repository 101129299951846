// PACKAGE IMPORTS
// ==========================================================================
import React from 'react'
import Form from 'react-jsonschema-form'
// import extraFields from 'react-jsonschema-form-extras'
import { toast } from 'react-toastify'

// UTILS & FUNCTIONS IMPORTS
// ==========================================================================
import renderSchema from '../../core/functions/renderSchema'
import { useQ, useEditElement } from '../../services/toile'

import Loader from '../../components/Loader'

const { default: extraFields } =
  typeof window !== 'undefined' && require('react-jsonschema-form-extras')
// let extraFields = null
// if (typeof window !== 'undefined') {
//   import('react-jsonschema-form-extras').then(ef => {
//     extraFields = ef
//   })
// }
// CONTAINERS IMPORTS
// ==========================================================================
// COMPONENTS IMPORTS
// ==========================================================================
// DATA/CSS/IMAGES IMPORTS
// ==========================================================================
// ###########################################################################
// END OF IMPORTS
// ###########################################################################
// FUNCTIONS
// ==========================================================================
const log = type => console.log.bind(console, type)

// CONSTANTS
// ==========================================================================

const dataSucture = {
  intro: {
    title: '',
    text: ``,
    image: '',
    bgImage: '',
  },
  services: {
    title: '',
    text: '',
    list: [
      {
        title: '',
        text: ``,
        image: '',
      },
    ],
  },
  about: {
    title: '',
    text: ``,
    image: '',
  },
  gallery: {
    title: '',
    text: ``,
    list: [
      {
        title: '',
        text: '',
        image: '',
      },
    ],
  },
  cta: {
    title: '',
    text: ``,
    buttons: {
      title: '',
      text: '',
      list: [
        {
          title: '',
          key: '',
          // link: 'https://join.toile.io/',
          link: '',
        },
      ],
    },
  },
  contact: {
    title: '',
    text: '',
  },
}

const uiSchema = {
  intro: {
    text: {
      'ui:field': 'rte',
      rte: { format: 'html' },
      // 'ui:widget': 'textarea',
      // 'ui:options': { rows: 5 },
    },
  },
  services: {
    text: {
      'ui:field': 'rte',
      rte: { format: 'html' },
    },
    list: {
      items: {
        text: {
          'ui:field': 'rte',
          rte: { format: 'html' },
        },
      },
    },
  },
  about: {
    text: {
      'ui:field': 'rte',
      rte: { format: 'html' },
    },
  },
  gallery: {
    text: {
      'ui:field': 'rte',
      rte: { format: 'html' },
    },
  },
  cta: {
    text: {
      'ui:field': 'rte',
      rte: { format: 'html' },
    },
  },
  contact: {
    text: {
      'ui:field': 'rte',
      rte: { format: 'html' },
    },
  },
}
const dataStructure = {
  type: 'object',
  properties: {
    intro: {
      type: 'object',
      properties: {
        title: { type: 'string' },
        text: { type: 'string' },
        image: { type: 'string', enum: [], enumNames: [] },
        bgImage: { type: 'string', enum: [], enumNames: [] },
      },
    },
    services: {
      type: 'object',
      properties: {
        title: { type: 'string' },
        text: { type: 'string' },
        list: {
          type: 'array',
          items: {
            type: 'object',
            properties: {
              title: { type: 'string' },
              text: { type: 'string' },
              image: { type: 'string', enum: [], enumNames: [] },
            },
          },
        },
      },
    },
    about: {
      type: 'object',
      properties: {
        title: { type: 'string' },
        text: { type: 'string' },
        image: { type: 'string', enum: [], enumNames: [] },
      },
    },
    gallery: {
      type: 'object',
      properties: {
        title: { type: 'string' },
        text: { type: 'string' },
        list: {
          type: 'array',
          items: {
            type: 'object',
            properties: {
              title: { type: 'string' },
              // text: { type: 'string' },
              image: { type: 'string', enum: [], enumNames: [] },
            },
          },
        },
      },
    },
    cta: {
      type: 'object',
      properties: {
        title: { type: 'string' },
        text: { type: 'string' },
        buttons: {
          type: 'object',
          properties: {
            // title: { type: 'string' },
            // text: { type: 'string' },
            list: {
              type: 'array',
              items: {
                type: 'object',
                properties: {
                  title: { type: 'string' },
                  key: { type: 'string' },
                  link: { type: 'string' },
                },
              },
            },
          },
        },
      },
    },
    contact: {
      type: 'object',
      properties: {
        title: { type: 'string' },
        text: { type: 'string' },
      },
    },
  },
}

const websiteId = process.env.GATSBY_WEBSITE_ID
// COMPONENTS
// ==========================================================================

// ###########################################################################
// MAIN COMPONENT
// ###########################################################################
const PageAdminOnePage = () => {
  // HOOKS
  // ==========================================================================
  const [editElement, editElementIsLoading] = useEditElement()
  const { data, error, loading, refetch: refreshWebsiteElements } = useQ(
    'WEBSITE_ELEMENTS',
  )

  // ----------------------------------------------------------------------------
  if (loading) return <Loader />
  if (error) return `Error! ${error.message}`
  // --------------------------------------------------------------------------

  // Isolate important values from DB
  const website = data?.currentUser?.websites[0] || {}
  const elements = website?.elements || []
  const {
    OnePageElement,
    // OnePageComponent,
    MediaLibraryElement,
    // MediaLibraryComponent,
  } = elements.reduce((accu, element) => {
    const { name: elementName, component: compo } = element || {}
    const { name: componentName } = compo
    return {
      ...accu,
      [`${elementName}Element`]: element,
      [`${componentName}Component`]: compo,
    }
  }, {})
  // --------------------------------------------------------------------------

  // Create Select fields from image library
  const mediaLibraryImages = MediaLibraryElement?.contents?.images || []
  const imagesEnum = mediaLibraryImages.map(({ id }) => id)
  const imagesEnumNames = mediaLibraryImages.map(({ name }) => name)
  dataStructure.properties.intro.properties.image.enum = imagesEnum
  dataStructure.properties.intro.properties.image.enumNames = imagesEnumNames
  dataStructure.properties.intro.properties.bgImage.enum = imagesEnum
  dataStructure.properties.intro.properties.bgImage.enumNames = imagesEnumNames
  dataStructure.properties.services.properties.list.items.properties.image.enum = imagesEnum
  dataStructure.properties.services.properties.list.items.properties.image.enumNames = imagesEnumNames
  dataStructure.properties.about.properties.image.enum = imagesEnum
  dataStructure.properties.about.properties.image.enumNames = imagesEnumNames
  dataStructure.properties.gallery.properties.list.items.properties.image.enum = imagesEnum
  dataStructure.properties.gallery.properties.list.items.properties.image.enumNames = imagesEnumNames
  // --------------------------------------------------------------------------

  // Current content to prefill the form
  const currentContents = OnePageElement?.contents
  // --------------------------------------------------------------------------
  const onSubmit = ({ formData }) => {
    console.log('formData', formData)

    return editElement({
      previous: OnePageElement,
      next: {
        name: 'OnePage',
        locale: 'fr',
        website: websiteId,
        component: OnePageElement.component.id,
        contents: formData,
      },
    })
      .then(() => {
        refreshWebsiteElements()
        toast('Data successfuly saved')
      })
      .catch(err => {
        toast(`Error data submition: ${err}`)
      })
  }

  // RETURN
  // ***************************************************************************
  return renderSchema({
    schema: [
      editElementIsLoading && {
        component: Loader,
        css: {
          position: 'fixed',
        },
      },
      {
        component: Form,
        noHtml5Validate: true,
        fields: extraFields,
        // onChange: log('changed'),
        onSubmit,
        onError: log('errors'),
        css: {
          // ' label > span': {
          //   display: 'block',
          //   paddingTop: '1em',
          //   fontWeight: 'bold',
          // },
        },
        formData: currentContents,
        uiSchema,
        schema: dataStructure,
      },
    ],
  })
}

export default PageAdminOnePage
